import {
  OTHER_SERVICE_LIST_ROUTE_NAME,
  PRICE_LIST_ROUTE_NAME,
  SUPPLIES_CATEGORY_LIST_ROUTE_NAME,
  SUPPLIES_LIST_ROUTE_NAME
} from '@/router';

export default [
  // {
  //   title: 'ContentPage',
  //   route: 'content-page',
  //   icon: 'BookIcon'
  // },
  // {
  //   title: 'PriceNavigation',
  //   route: PRICE_LIST_ROUTE_NAME,
  //   icon: 'DollarSignIcon'
  // },
  // {
  //   title: 'NewsManagement',
  //   route: 'blog',
  //   icon: 'FileTextIcon'
  // },
  // {
  //   title: 'Sample',
  //   route: 'sample-list',
  //   icon: 'TabletIcon'
  // },
  {
    title: 'Setting',
    route: 'setting',
    icon: 'SettingsIcon'
  },
  // {
  //   title: 'FormSampleNavigation',
  //   route: 'shape-list',
  //   icon: 'ClipboardIcon'
  // },
  {
    title: 'FormSampleNavigation',
    route: 'sample-type-list',
    icon: 'ClipboardIcon'
  },
  {
    title: 'ProductCategory',
    route: 'product-category-list',
    icon: 'ListIcon'
  },
  {
    title: 'SuppliesCategory',
    route: SUPPLIES_CATEGORY_LIST_ROUTE_NAME,
    icon: 'ListIcon'
  },
  {
    title: 'Supplies',
    route: SUPPLIES_LIST_ROUTE_NAME,
    icon: 'PackageIcon'
  }
  // {
  //   title: 'Slider',
  //   route: 'slider',
  //   icon: 'SlidersIcon'
  // },
  // {
  //   title: 'OtherService',
  //   route: OTHER_SERVICE_LIST_ROUTE_NAME,
  //   icon: 'ServerIcon'
  // }
];
