import ApiClientFactory from '@/api/apiClientFactory';
import Vue from 'vue';

import {
  OrderAction,
  OrderGetter,
  OrderModule,
  OrderMutation,
  OrderState
} from './order.module-types';

export const orderModule: OrderModule = {
  namespaced: true,
  state: {
    [OrderState.order]: null,
    [OrderState.orderMaterial]: null,
    [OrderState.companyInvoiceInfo]: {
      companyName: 'CÔNG TY TNHH TM-DV IN THÁI BÌNH',
      companyAddress:
        '147/15 Đường ĐX 43, P. Phú Mỹ, TP. Thủ Dầu Một, Bình Dương',
      companyPhone:
        'Tel: 02743 660 999 - Tư vấn báo giá: 0927 28 28 28 - Web: nhainthaibinh.com'
    }
  },
  mutations: {
    [OrderMutation.setOrder]: (state, payload) => {
      state[OrderState.order] = payload;
    },
    [OrderMutation.setOrderMaterial]: (state, payload) => {
      state[OrderState.orderMaterial] = payload;
    },
    [OrderMutation.setCompanyInvoiceInfo]: (state, payload) => {
      state[OrderState.companyInvoiceInfo] = {
        companyAddress: payload?.companyAddress ?? '',
        companyPhone: payload?.companyPhone ?? '',
        companyName: payload?.companyName ?? ''
      };
    },
    [OrderMutation.resetCompanyInvoiceInfo]: (state) => {
      state[OrderState.companyInvoiceInfo] = {
        companyName: 'CÔNG TY TNHH THẾ GIỚI STICKER',
        companyAddress: '119/45 Nguyễn Hồng Đào, P.14, Q.Tân Bình, TP.HCM',
        companyPhone:
          'Tel: 02743 660 999 - Tư vấn báo giá: 0927 28 28 28 - Web: nhainthaibinh.com'
      };
    }
  },
  actions: {
    [OrderAction.loadOrder]: async ({ commit }, id: number) => {
      commit(
        OrderMutation.setOrder,
        await new ApiClientFactory().orderClient().get(id)
      );
    },
    [OrderAction.clearOrder]: ({ commit }) => {
      commit(OrderMutation.setOrder, null);
    },
    [OrderAction.getOrderMaterial]: async ({ commit }, productId) => {
      const clientApi = new ApiClientFactory();
      const result = await clientApi
        .orderClient()
        .getOrderMaterialByProductId(productId);

      commit(OrderMutation.setOrderMaterial, result);
    }
  },
  getters: {
    [OrderGetter.order]: (state) => state[OrderState.order],
    [OrderGetter.companyInvoiceInfo]: (state) =>
      state[OrderState.companyInvoiceInfo]
  }
};
